<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-form ref="form">
      <v-layout wrap justify-center>
        <v-flex xs12 lg8 pa-0 pa-sm-6>
          <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
            <!-- <v-layout>
            <v-flex
              xs4
              class="text-left nsbold"
              style="font-weight: 700; font-size: 20px"
            >
              <v-icon class="pr-2" color="#9A8CFC">mdi-account-plus</v-icon>Add
              New Students</v-flex
            >
            <v-flex
              xs4
              class="text-left nssbold"
              style="font-weight: 400; color: #797979; font-size: 18px"
              @click="$router.push({ path: 'student' })"
            >
              <v-icon class="pr-2">mdi-school-outline</v-icon>Students
              List</v-flex
            >
          </v-layout> -->
            <v-layout wrap>
              <v-flex pb-md-2 pl-md-4>
                <h3
                  class="text-left nsbold"
                  style="font-weight: 700; font-size: 20px"
                >
                  Outpass Generation
                </h3>
              </v-flex>
            </v-layout>
            <v-layout style="font-weight: 400" wrap>
              <v-flex xs12 md6 pr-md-2 py-2>
                <v-text-field
                  v-model="user.admissionNo"
                  dense
                  hide-details
                  label="Admission No"
                  outlined
                  @change="stdetails()"
                  :rules="[(v) => !!v || 'Admission number is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 pl-md-2 py-2>
                <v-text-field
                  v-model="user.purpose"
                  dense
                  hide-details
                  label="Purpose"
                  outlined
                  :rules="[(v) => !!v || 'purpose is required']"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout style="font-weight: 400" wrap>
              <v-flex xs12 md6 pr-md-2 py-2>
                <v-text-field
                  v-model="name"
                  dense
                  hide-details
                  disabled
                  label="Name"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 pl-md-2 py-2>
                <TestPicker :refresh1="refresh1" @stepper="testStep" />
              </v-flex>
            </v-layout>
            <v-layout style="font-weight: 400" wrap>
              <!-- <v-flex xs12 md6 pr-md-2>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="user.fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="user.fromDate"
                    label="From date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="user.fromDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(user.fromDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6 pl-md-2>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="user.toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="user.toDate"
                    label="To date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="user.toDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(user.toDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex> -->
              <v-flex xs12 md6 pr-md-2 py-2>
                <v-text-field
                  v-model="classs"
                  dense
                  disabled
                  hide-details
                  label="Class"
                  outlined
                  @change="stdetails()"
                  :rules="[(v) => !!v || 'Admission number is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 pl-md-2 py-2>
                <!-- <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="user.toDate"
                  transition="scale-transition"
                  offset-y
                  max-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="user.toDate"
                      label="To"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="user.toDate" 
                    full-width
                    @click:minute="$refs.menu.save(user.toDate)"
                  ></v-time-picker>
                </v-menu> -->

                <v-dialog
                  ref="dialog"
                  v-model="menu2"
                  :return-value.sync="user.toDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="user.toDate"
                      label="To" 
                      readonly
                      outlined
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="menu2" v-model="user.toDate" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(user.toDate)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
                <!-- <TestPicker2 :refresh2="refresh2" @stepper2="testStep2" /> -->
                <!-- <v-datetime-picker label="Select to date" outlined v-model="user.toDate"> </v-datetime-picker> -->
              </v-flex>
            </v-layout>
            <v-layout style="font-weight: 400" wrap>
              <v-flex xs12 md6 pr-md-2 py-2>
                <v-text-field
                  v-model="school"
                  dense
                  hide-details
                  disabled
                  label="School"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 pl-md-2 py-2>
                <v-text-field
                  v-model="user.destination"
                  dense
                  label="Destination"
                  outlined
                  :rules="[(v) => !!v || 'admission number is required']"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <!-- <div
            style="
              border-top: 3px dashed grey;
              border-top-width: 2px;
              width: 810px;
            "
          ></div> -->
            <!------------------------------------------------->
            <v-flex xs12 md6 align-self-center class="nsregular mt-2" >
              <v-btn
                style="font-size: 14px; font-weight: 400"
                color="#766BC0"
                dark
                block
                depressed
                large
                hide-details
                @click="dialoge = true"
                >SUBMIT
              </v-btn>
            </v-flex>
          </v-card>
          <!---------------dialogue----------------->
          <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text">
                <v-layout wrap>
                  <v-flex xs6> Admission No: {{ user.admissionNo }} </v-flex>
                  <v-flex xs6> Purpose: {{ user.purpose }} </v-flex>
                  <v-flex xs6> Name: {{ name }} </v-flex>
                  <v-flex xs6> From Date: {{ user.fromDate }} </v-flex>
                  <v-flex xs6> Class: {{ classs }} </v-flex>
                  <v-flex xs6> To: {{ user.toDate }} </v-flex>
                  <v-flex xs6> School: {{ school }} </v-flex>
                  <v-flex xs6> Destination: {{ user.destination }} </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="add()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar
            :timeout="1000"
            :value="true"
            right
            color="red"
            v-model="snackbar"
          >
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
import TestPicker from "./testPicker";
// import TestPicker2 from "./testPicker2";
export default {
  components: {
    TestPicker,
    // TestPicker2,
  },
  data() {
    return {
      dateOnly: null,
      name: null,
      classs: null,
      school: null,
      snackbar: false,
      ServerError: false,
      appLoading: false,
      dialoge: false,
      modal2: false,
      menu: false,
      menu2: false,
      show1: false,
      msg: null,
      stdata: [],
      user: {
        admissionNo: null,
        purpose: null,
        destination: null,
        fromDate: null,
        toDate: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      firstcheckbox: false,
      Myname: [],
      refresh2: false,
      refresh1: false,
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  // mounted(){
  //   this.stdetails();
  // },
  methods: {
    testStep(window_data) {
      this.user.fromDate = window_data.mod;
      this.dateOnly = this.user.fromDate.slice(0, 10);
    },
    // testStep2(window_data) {
    //   this.user.toDate = window_data.mod;
    //   this.user.toDate = this.user.toDate.slice(11,5)
    //   console.log("Win2=", window_data.mod);
    // },
    stdetails() {
      // if (this.$refs.form.validate()) {
      //   this.user.classid = this.myClass;
      axios({
        method: "post",
        url: "/admissionnumber/studentdetails",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          admissionNo: this.user.admissionNo,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.snackbar = true;
            this.msg = response.data.msg;
            this.name = response.data.data.name;
            this.classs = response.data.data.studentclass.className;
            this.school = response.data.data.school.SchoolName;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
            // if (this.snackbar == false) {
            //   this.$router.go();
            // }

            // setTimeout(() => this.$router.go(),3000)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    add() {
      if (this.$refs.form.validate()) {
        this.user.classid = this.myClass;

        this.user.toDate = this.dateOnly + " " + this.user.toDate;
        axios({
          method: "post",
          url: "/outpass/issue/data",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: this.user,
        })
          .then((response) => {
            if (response.data.status == true) {
              this.snackbar = true;
              this.msg = response.data.msg;
              this.dialoge = false;
              this.user = {};
              this.$refs.form.resetValidation();
              if (this.snackbar == false) {
                this.$router.go();
              }
              setTimeout(() => this.$router.go(), 3000);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
              this.dialoge = false;
              this.user = {};
              this.$refs.form.resetValidation();
              if (this.snackbar == false) {
                this.$router.go();
              }
              setTimeout(() => this.$router.go(), 3000);
            }

            this.refresh2 = true;
            this.refresh1 = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.v-date-picker {
  color: red !important;
}
</style>